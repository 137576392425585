// React
import React, { useState } from 'react';

// react-router components
import { useNavigate, useParams } from "react-router-dom";

// MUI
// import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Manzia
import { nestedNavItems } from 'layouts/settings/data/navItems';

export default function NestedList() {
  const { settingItem } = useParams();
  const nestedOpen = {};
  nestedNavItems.forEach(item => { nestedOpen[item.key] = item.key === settingItem });
  const [open, setOpen] = useState(nestedOpen);
  const navigate = useNavigate();

  const handleClick = (key) => {
    setOpen((prevState) => (
      { ...prevState, [key]: !prevState[key] }
    ));
  };

  function renderNestedItems(items) {
    return items.map((item, key) => {
      const { label, href } = item;
      const itemKey = `item-${key}`;

      return (
        <ListItemButton 
          sx={{ pl: 4 }}
          key={itemKey}
          onClick={() => navigate(`/settings/${href}`)}
        >
          <ListItemText 
            primary={label} 
            primaryTypographyProps={{ variant: "subtitle2", fontWeight: "regular", textTransform: "none", gutterBottom: true }} 
          />
        </ListItemButton>
      );
    });
  }

  function renderNavItems() {
    return nestedNavItems.map((item) => {
      const { icon, label, nested, key } = item; 

      return (
        <React.Fragment key={key}>
          <ListItemButton onClick={() => handleClick(key)}>
            <ListItemIcon>
            <Icon fontSize="small">{icon}</Icon>
            </ListItemIcon>
            <ListItemText 
              primary={label} 
              primaryTypographyProps={{ variant: "subtitle2", fontWeight: "regular", textTransform: "none", gutterBottom: true }} 
            />
            {open[key] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Divider variant="inset" component="li" />
          <Collapse in={open[key]} timeout="auto" unmountOnExit>
            <List component="div">
              {renderNestedItems(nested)}
            </List>
          </Collapse>
        </React.Fragment>
      );
    });
  }

  return (
    <List
      sx={{ 
        width: '100%', 
        maxWidth: 360, 
        bgcolor: 'background.paper',
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
      }}
      component="nav"
      aria-labelledby="settings-list"
    >
      {renderNavItems()}
    </List>
  );
}