/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState, useRef } from "react";

// @mui material components
// import Card from "@mui/material/Card";

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// Material Dashboard 2 PRO React examples
// import DataTable from "examples/Tables/DataTable";

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Manzia
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import NoDataView from 'layouts/common/NoDataView';
import { productActions } from 'appSettings';

// Table
import DefaultCell from 'layouts/analytics/components/DefaultCell';
import ActionCell from 'layouts/analytics/components/ActionCell';

// DataGrid
import { DataGrid, GridColumnMenu, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

// Utils
import moment from 'moment';
import MDBox from "components/MDBox";

// Constants
const PAGE_SIZE = 10;
const FETCH_LIMIT = 100;
const PAGE_SIZES = [10, 25, 50];

// const COLUMNS = [
//   { Header: "email", accessor: "emailAddress", align: "center", width: "10%" },
//   { Header: "session", accessor: "sessionId", align: "center", width: "10%" },
//   { Header: "utterance", accessor: "emailUtterance", width: "50%" },
//   { Header: "submitted", accessor: "createdAt", align: "center", width: "20%" },
//   { Header: "actions", accessor: "actions", align: "center", width: "10%" },
// ];

const GRID_COLUMNS = [
  { field: 'emailAddress', headerName: 'Email', flex: 1, hideable: false,
    renderCell: (params) => (
      <DefaultCell>{params.value}</DefaultCell>
    )
  },
  { field: 'sessionId', headerName: 'Session', flex: 1,
    renderCell: (params) => (
      <DefaultCell>{params.value}</DefaultCell>
    )
  },
  { field: 'emailUtterance', headerName: 'Utterance', width: 200,
    renderCell: (params) => (
      <DefaultCell>{params.value}</DefaultCell>
    )
  },
  { field: 'createdAt', headerName: 'Submitted', flex: 1, 
    renderCell: (params) => (
      <DefaultCell>{moment(params.value).fromNow()}</DefaultCell>
    ) 
  },
  { field: 'actions', type: "actions", headerName: 'Actions', flex: 0.5,
    renderCell: (params) => (
      <ActionCell 
        icons={[
        {icon: "chevron_right", color: "info", action: productActions.info } ]} 
        payload={{ ...params }} 
      />
    )
  },
];

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

/**
 * 
 * 
  "data": {
    "manziaEmailLeadsByAccount": {
      "items": [
        {
          "accountId": "3e3ea2ca-e0a5-465a-bf1f-df03e57f69c0",
          "emailAddress": "roymanzi@gmail.com",
          "emailUtterance": "just browsing and my emails are roymanzi@yahoo.com, roymanzi@gmail.com",
          "sessionId": "726237597895677",
          "createdAt": "2023-10-18T08:08:55.486Z"
        },
        {
          "accountId": "3e3ea2ca-e0a5-465a-bf1f-df03e57f69c0",
          "emailAddress": "roymanzi@yahoo.com",
          "emailUtterance": "just browsing and my emails are roymanzi@yahoo.com, roymanzi@gmail.com",
          "sessionId": "726237597895677",
          "createdAt": "2023-10-18T08:08:55.486Z"
        }
      ]
    }
  }
}
 */

function EmailLeads() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { account: { id: accountId } } = useLocalChatbotUser({ userId: USERID });
  
  // Pagination
  const [emailLeads, setEmailLeads] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const nextTokenSet = useRef(new Set());
  
  const [rowCountState, setRowCountState] = useState(0);

  const { loading, error, data, fetchMore } = useQuery(gql`${queries.manziaEmailLeadsByAccount}`, {
    variables: {
      accountId,
      limit: FETCH_LIMIT
    }, fetchPolicy: 'cache-and-network'
  });

  function removeDuplicates(items, keyFields) {
    const temp = {};
    items.forEach(item => {
      const itemKey = keyFields.map(key => item[key]).join("_");
      temp[itemKey] = item;
    });
    return Object.values(temp);
  }

  function paginateArray(array, pageIndex, pageSize) {
    // Calculate the start index
    const startIndex = pageIndex * pageSize;

    // Slice the array from the start index up to the start index + page size
    return array.slice(startIndex, startIndex + pageSize);
  }


  // Update rows
  useEffect(() => {
    if (!data) return;
    // console.log("Data: ", data);
    // Rows
    let rows = data.manziaEmailLeadsByAccount?.items ?? [];
    rows = removeDuplicates([ ...emailLeads, ...rows], ["sessionId", "emailAddress"]);
    setRowCountState(rows.length);
    // Leads
    setEmailLeads(rows);
    
  }, [data]);

  // Fetch more
  useEffect(() => {
    if (!data?.manziaEmailLeadsByAccount?.nextToken || 
      nextTokenSet.current.has(data?.manziaEmailLeadsByAccount?.nextToken)) return;

    const lastPage = Math.floor(emailLeads.length / paginationModel.pageSize);
    if (paginationModel.page === lastPage && data.manziaEmailLeadsByAccount?.nextToken) {
      fetchMore({
        variables: {
          nextToken: data.manziaEmailLeadsByAccount.nextToken,
          limit: FETCH_LIMIT
        },
      });
      nextTokenSet.current.add(data.manziaEmailLeadsByAccount.nextToken);
    }
  }, [data?.manziaEmailLeadsByAccount?.nextToken]);

  const handlePaginationModelChange = (newPaginationModel) => {
    // We have the cursor, we can allow the page transition.
    // console.log("Pagination model: ", newPaginationModel);

    setPaginationModel(newPaginationModel);
    
  };

  // const getActions = useCallback((params) => [
  //   <GridActionsCellItem
  //     icon={<Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>}
  //     label="Detail"
  //     onClick={navigate(`/engage/session/${params.sessionId}`, { state: { lead: { ...params } } })}
  //   />,
  // ], []);

  if (loading) {
    return (<LoadView loadingMessage="Fetching leads..." />);
  }

  if (error) {
    return (<ErrorView error={{ message: "Encountered error while fetching leads. Try again later. "}}/>);
  }

  // let leads = data.manziaEmailLeadsByAccount?.items ?? [];
  if (emailLeads.length === 0) {
    return (
    <NoDataView 
      info={{ 
        title: "No emails leads", 
        message: "Click below to learn about best practices you can implement to increase email lead generation." 
      }}
      action={{
        label: "Learn more about generating email leads",
        href: "https://www.manzia.com/help/performance/bot-leads/"
      }}
    />
    );
  }

  // console.log(`Number of rows: ${rowCountState}, number of leads: ${emailLeads.length}`);
  return (
    <MDBox sx={{ height: 400, width: '100%' }}>
      <DataGrid 
        rows={paginateArray(emailLeads, paginationModel.page, paginationModel.pageSize)}
        getRowId={(row) => `${row.sessionId}:${row.emailAddress}`} 
        columns={GRID_COLUMNS}
        getRowHeight={() => 'auto'}
        autosizeOptions={{
          includeOutliers: true,
        }}
        slots={{ 
          columnMenu: CustomColumnMenu,
          toolbar: CustomToolbar, 
        }}
        pageSizeOptions={PAGE_SIZES}
        paginationMode="server"
        rowCount={rowCountState}
        onPaginationModelChange={handlePaginationModelChange}
        paginationModel={paginationModel}
        loading={loading}
      />
    </MDBox>
    
  );

  // return (
  //   <DataTable 
  //     table={getTableData(leads)} 
  //     canSearch
  //     onLoadMore={() => fetchMore({
  //       variables: {
  //         nextToken: data.manziaEmailLeadsByAccount.nextToken,
  //         limit: 100
  //       },
  //     })} 
  //   /> 
  // );
}

export default EmailLeads;
