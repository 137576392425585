/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useCallback, useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
// import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// GraphQL
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Manzia
import NotificationView from 'layouts/common/Notification';
import { UserFlows, ManziaAuthState, OnboardState, 
    OnboardRole, ManziaAuthKeys, MANZIA_CAPTCHA_KEY } from "appSettings";
import InputField from "layouts/authentication/components/InputField";
import PasswordField from "layouts/authentication/components/PasswordField";
import useAuth from "customhooks/useAuth";
import ErrorView from "layouts/common/ErrorView";
import LoadView from "layouts/common/LoadView";
import useLocalStorage from "customhooks/useLocalStorage";
import { isEmptyObject } from "helpers";

// schemas for form and form fields
import validations from "layouts/authentication/schema/validations";
import form from "layouts/authentication/schema/form";
import initialValues from "layouts/authentication/schema/initialValues";

function AuthLogin({ authHandler }) {
    const { login: { formId, loginEmail, loginPassword } } = form;
    const userAccountRef = useRef(null);
    const navigate = useNavigate();
    // const [rememberMe, setRememberMe] = useState(false);

    // Recaptcha
    const recaptchaRef = useRef();
    const [recaptchaError, setRecaptchaError] = useState(null);

    // Signup user
    const [signupUser, setSignupUser] = useLocalStorage(ManziaAuthKeys.signupUser, {});

    // Login
    const { loading: loginLoading, error: loginError, 
        value: loginUser, command: authLogin } = useAuth(ManziaAuthState.signIn.key);
    
    // Fetch and cache user details
    const [getManziaUser, { loading, error, data, called }] = useLazyQuery(gql`${queries.getManziaUser}`, {
        fetchPolicy: 'cache-and-network',
    });

    // Fetch onboard state
    const [getOnboardState, { loading: onboardLoading, error: onboardError, called: onboardCalled,
        data: onboardData }] = useLazyQuery(gql`${queries.getManziaOnboard}`, {
            fetchPolicy: 'cache-and-network',
        });

    // Fetch chat webUI
    const [getChabotWebUI, { loading: chatLoading, error: chatError, called: chatCalled,
        data: chatData }] = useLazyQuery(gql`${queries.getManziaChabotWebUI}`, {
        fetchPolicy: 'cache-and-network',
    });

    // Update onboard state
    const [updateOnboard, { data: onboardUpdateData, loading: onboardUpdateLoading, 
        error: onboardUpdateError }] = useMutation(gql`${mutations.updateManziaOnboard}`);

   useEffect(() => {
       if (!loginUser) return;
       // console.log("Login user: ", loginUser);
        /**
         * user.attributes: {sub: '10225566-9903-4b6a-aaa9-561d78f72cf2', email_verified: true, name: 'Roy Manzi', email: 'roymanzi@yahoo.com'}
         */
       switch (loginUser.challengeName) {
        case 'NEW_PASSWORD_REQUIRED':
            // Change password
            authHandler({ state: ManziaAuthState.forceNewPassword, signUser: loginUser });
            return;
        default:
            break;
       }

       // Get ManziaUser
       const { username } = loginUser;
       getManziaUser({
           variables: { id: username }
       });
   }, [loginUser]);

    useEffect(() => {
        if (!loginError) return;
        const { name: errorCode } = loginError;
        switch (errorCode) {
            case 'UserNotConfirmedException':
                authHandler({state: ManziaAuthState.confirmSignUp, user: signupUser });
                break;
            case 'PasswordResetRequiredException':
                authHandler({ state: ManziaAuthState.resetPassword });
                break;
            default:
                break;
        }
    }, [loginError]);

    useEffect(() => {
        if (!data || !called || loading) return;

        const { username, attributes: { email } } = loginUser;

        if (!data.getManziaUser) {
            // Manzia User does not exist
            navigate("/onboard/backend", { state: { 
                userFlow: UserFlows.SIGNUP_FLOW, 
                userId: username,
                userEmail: email,
                manziaAccountId: userAccountRef.current
            }, replace: true });
            return;
        }

        // Check onboarding state if owner
        const { roleId, accountId } = data.getManziaUser;
        switch (roleId) {
            case OnboardRole.DEVELOPER:
                // Navigate to settings
                navigate("/settings/install-bot", { state: {
                    navItemPath: 'install-bot'
                    }, replace: true });
                break;
            case OnboardRole.TEAM_MEMBER:
                // Navigate to home page
                navigate("/products", { replace: true });
                break;
            default:
                // Assume is Account owner
                userAccountRef.current = accountId;
                getOnboardState({
                    variables: { emailAddress: email }
                });
                break;
        }
        
    }, [loading, called])

    // Cache ID: ManziaOnboard:{"emailAddress":"roymanzi@yahoo.com"}
    useEffect(() => {
        if (!onboardData || !onboardCalled || onboardLoading) return;
        
        const { username, attributes: { email } } = loginUser;

        if (!onboardData.getManziaOnboard) {
            // No onboard data
            navigate("/onboard/backend", { state: { 
                userFlow: UserFlows.SIGNUP_FLOW, 
                userId: username,
                userEmail: email,
                manziaAccountId: userAccountRef.current
            }, replace: true });
            return;
        }

        const { onboardState, accountId } = onboardData.getManziaOnboard;
        const chatbotId = data.getManziaUser.userAccount?.v2chatBotId;

        switch (onboardState) {
            case OnboardState.ONBOARD_STARTED:
                // In the case, the ChabotWebUI was not created or the subscriptions
                // may have failed.
                if (chatbotId) {
                    getChabotWebUI({ variables: { id: chatbotId } });
                } else {
                    // Contact support
                    const { emailAddress, id: userId } = data.getManziaUser;
                    updateOnboard({
                        variables: {
                            input: {
                                emailAddress,
                                userId,
                                accountId,
                                onboardState: OnboardState.NO_CHATWEBUI
                            }
                        }
                    });
                }
                
                break;
            case OnboardState.ONBOARD_COMPLETE:
            case OnboardState.NO_PRODUCTS:
                // Navigate to welcome
                navigate("/onboard/welcome", {
                    state: {
                      onboardState: OnboardState.NO_PRODUCTS,
                      userId: username,
                      userEmail: email
                    }, replace: true });
                break;
            case OnboardState.ACCOUNT_FAILED:
            case OnboardState.CREATE_BOT_FAILED:
            case OnboardState.NO_CHATWEBUI:
                // Recreate the backend
                navigate("/onboard/backend", { state: { 
                    userFlow: UserFlows.SIGNUP_FLOW, 
                    userId: username,
                    userEmail: email,
                    manziaAccountId: accountId
                }, replace: true });
                break;
            case OnboardState.CREATED_PRODUCT:
            case OnboardState.PRODUCT_ERROR:
                navigate("/products", { replace: true });
                break;
            default:
                break;
        }
    }, [onboardCalled, onboardLoading]);

    useEffect(() => {
        if (!chatData || !chatCalled || chatLoading) return;
        const { emailAddress, id: userId, accountId } = data.getManziaUser;
        updateOnboard({
            variables: {
                input: {
                    emailAddress,
                    userId,
                    accountId,
                    onboardState: chatData.getChabotWebUI ? OnboardState.ONBOARD_COMPLETE : OnboardState.NO_CHATWEBUI
                }
            }
        });
    }, [chatLoading, chatCalled]);

    useEffect(() => {
        if (!onboardUpdateData) return;
        const { onboardState } = onboardUpdateData.updateManziaOnboard;
        if (onboardState === OnboardState.ONBOARD_COMPLETE) {
            // Navigate to home page
            navigate("/products", { replace: true });
        }
    }, [onboardUpdateData]);


  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = useCallback((values, actions) => {
    // console.log("Submitted: ", values);

    // Save local
    if (isEmptyObject(signupUser)) {
        setSignupUser({ username: values[loginEmail.name] });
    }
    
    // Login
    recaptchaRef.current.executeAsync()
            .then((recaptchaToken) => {
                authLogin()({
                    username: values[loginEmail.name], 
                    password: values[loginPassword.name],
                    validationData: {
                        recaptchaToken
                    },
                });
            }).catch(() => {
                setRecaptchaError("Login validation failed. Try again later.");
                // console.log("Recaptcha error: ", error);

            }).finally(() => recaptchaRef.current.reset());

    actions.setSubmitting(false);
    // actions.resetForm();
    
  }, []);

  const getLoginErrMessage = () => {
    if (!loginError) return loginError;
    const { name: errorCode } = loginError;
    switch (errorCode) {
        case 'InvalidPasswordException':
            return "Invalid email and or password provided."
        case 'UserNotFoundException':
            return "Invalid email and or password provided.";
        case 'TooManyRequestsException':
            return "Too many login attempts. Wait for a few minutes and retry.";
        case 'NotAuthorizedException':
        case 'ForbiddenException':
            return "Please check your email for further instructions on how to proceed with login.";
        case 'UserNotConfirmedException':
            return null;
        case 'PasswordResetRequiredException':
            return null;
        default:
            return "Encountered an error during login. Please retry later.";
    }
  }

  return (
    <Formik
        initialValues={initialValues.login}
        validationSchema={validations.login}
        onSubmit={handleSubmit}
        validateOnMount
    >
        {({ isSubmitting, isValid }) => {
            // console.log("Form values", values);
            // console.log("Field states: ", fieldStates)
            // console.log("Errors: ", errors);

            const errLogin = getLoginErrMessage() || recaptchaError;
            const withSupport = onboardUpdateData?.updateManziaOnboard?.onboardState === OnboardState.ONBOARD_SUPPORT
            || onboardData?.getManziaOnboard?.onboardState === OnboardState.ONBOARD_SUPPORT;
            const isDisabled = loginLoading || loading || isSubmitting || !isValid 
            || error || chatLoading || onboardUpdateLoading 
            || onboardLoading || onboardError || onboardUpdateError || chatError || withSupport;
            return (
            <Form id={formId} autoComplete="on">
                {(withSupport) && <NotificationView 
                    color="info"
                    message="Invalid state. Manzia Support Team will contact you via email shortly."
                />}
                {(onboardLoading || chatLoading || onboardUpdateLoading) && <LoadView />}
                {(errLogin || error || onboardError || chatError || onboardUpdateError) && <ErrorView error={{ message: `${errLogin || error || onboardError || chatError || onboardUpdateError}` }} />}
                {/* {(data && !data.getManziaUser) && 
                    <ErrorView error={{ message: "Login attempt failed. Please try again." }} />} */}
                <MDBox my={2}>
                <InputField 
                    name={loginEmail.name} 
                    type={loginEmail.type}
                    label={loginEmail.label}
                    fullWidth 
                />
                </MDBox>
                <MDBox mb={2}>
                    <MDBox mb={1} display="flex" justifyContent="flex-end">
                        <MDTypography 
                            variant="button" 
                            color="info" 
                            fontWeight="bold"
                            sx={{ cursor: "pointer" }}
                            onClick={
                                () => authHandler({ state: ManziaAuthState.resetPassword })
                            }
                        >Forgot Password?</MDTypography>
                    </MDBox>
                    <PasswordField 
                        name={loginPassword.name} 
                        type={loginPassword.type}
                        label={loginPassword.label}
                        fullWidth 
                    />
                </MDBox>
                
                {/* <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                    &nbsp;&nbsp;Remember me
                </MDTypography>
                </MDBox> */}
                <MDBox 
                    mt={4} 
                    mb={1}
                    display="flex"
                    alignItems="center"
                >
                
                {(loading || loginLoading) && <CircularProgress color="secondary" />}
                <MDButton 
                    variant="gradient" 
                    color="info" 
                    size="large"
                    type="submit"
                    fullWidth
                    disabled={isDisabled}
                >
                    sign in
                </MDButton>
                </MDBox>
                <MDBox mt={3} display="flex" justifyContent="center">
                    <MDTypography variant="body2" color="dark" fontWeight="regular">
                        Don&apos;t have an account?{" "}
                    </MDTypography>
                    <MDTypography
                            variant="h6"
                            color="info"
                            fontWeight="bold"
                            sx={{ cursor: "pointer", marginLeft: 1 }}
                            onClick={
                                () => authHandler({ state: ManziaAuthState.signUp })
                            }
                        >
                            Sign up
                    </MDTypography>
                </MDBox>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={MANZIA_CAPTCHA_KEY}
                    badge="bottomright"
                />
            </Form>
          )}}
    </Formik>
  );

}

AuthLogin.propTypes = {
    authHandler: PropTypes.func.isRequired
};

export default AuthLogin;
