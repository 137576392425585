/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Card from "@mui/material/Card";

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Manzia
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import NoDataView from 'layouts/common/NoDataView';
import { productActions } from 'appSettings';

// Table
import DefaultCell from 'layouts/analytics/components/DefaultCell';
import ActionCell from 'layouts/analytics/components/ActionCell';

// Utils
import moment from 'moment';

// Constants
const COLUMNS = [
  { Header: "session", accessor: "sessionId", align: "center", width: "20%" },
  { Header: "initial utterance", accessor: "startUtterance", width: "50%" },
  { Header: "start time", accessor: "startedAt", align: "center", width: "20%" },
  { Header: "actions", accessor: "actions", align: "center", width: "10%" },
];

/**
 * 
 * 
  "data": {
    "manziaEmailLeadsByAccount": {
      "items": [
        {
          "accountId": "3e3ea2ca-e0a5-465a-bf1f-df03e57f69c0",
          "emailAddress": "roymanzi@gmail.com",
          "emailUtterance": "just browsing and my emails are roymanzi@yahoo.com, roymanzi@gmail.com",
          "sessionId": "726237597895677",
          "createdAt": "2023-10-18T08:08:55.486Z"
        },
        {
          "accountId": "3e3ea2ca-e0a5-465a-bf1f-df03e57f69c0",
          "emailAddress": "roymanzi@yahoo.com",
          "emailUtterance": "just browsing and my emails are roymanzi@yahoo.com, roymanzi@gmail.com",
          "sessionId": "726237597895677",
          "createdAt": "2023-10-18T08:08:55.486Z"
        }
      ]
    }
  }
}
 */

function timestampDaysAgo(period) {
  const { interval } = period;
  const days = Number.parseInt(interval.replace('day', ""), 10);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - days);
  return Math.floor(currentDate.getTime() / 1000);
}


function ConversationTable({ period }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { account: { id: accountId } } = useLocalChatbotUser({ userId: USERID });
  const timestamp = timestampDaysAgo(period);

  const { loading, error, data, fetchMore } = useQuery(gql`${queries.manziaConversationsByAccountIdAndStartTimestamp}`, {
    variables: {
      accountId, startTimestamp: { ge: timestamp }, sortDirection: 'DESC',
      limit: 100
    }, fetchPolicy: 'cache-and-network'
  });

  const getTableData = useCallback((conversations) => {
    const convArray = conversations.map((conv) => {
      const { startUtterance, sessionId, startTimestamp } = conv;
      return {
        startUtterance,
        sessionId,
        startedAt: moment.unix(startTimestamp).fromNow(),
        actions: { 
          icons: [
              {icon: "chevron_right", color: "info", action: productActions.info },
          ], 
          payload: { ...conv } 
        }
      }
    });
  
    const tableData = {
      columns: COLUMNS,
      rows: convArray.map((conv) => ({
            startUtterance: <DefaultCell>{conv.startUtterance}</DefaultCell>,
            sessionId: <DefaultCell>{conv.sessionId.substring(conv.sessionId.lastIndexOf('-') + 1)}</DefaultCell>,
            startedAt: <DefaultCell>{conv.startedAt}</DefaultCell>,
            actions: <ActionCell icons={conv.actions.icons} payload={conv.actions.payload} />
      }))
    };
  
    return tableData;
  }, [data])

  if (loading) {
    return (<LoadView loadingMessage="Fetching conversations..." />);
  }

  if (error) {
    return (<ErrorView error={{ message: "Encountered error while fetching conversations. Try again later. "}}/>);
  }

  const conversations = data.manziaConversationsByAccountIdAndStartTimestamp?.items ?? [];
  if (conversations.length === 0) {
    return (
      <NoDataView 
        info={{ 
          title: `No conversations in ${period.label.toLowerCase()}`, 
          message: "Click below to learn about best practices you can implement to increase conversations with web visitors." 
        }}
        action={{
          label: "Learn more about engaging web visitors in conversations",
          href: "https://www.manzia.com/help/performance/bot-conversations/"
        }}
      />
    );
  }

  return (
    <DataTable 
      table={getTableData(conversations)} 
      canSearch
      showTotalEntries
      onLoadMore={() => fetchMore({
        variables: {
          nextToken: data.manziaConversationsByAccountIdAndStartTimestamp.nextToken,
          limit: 100
        },
      })} 
    /> 
  );
}

ConversationTable.propTypes = {
  period: PropTypes.shape({
    interval: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired
}

export default ConversationTable;
