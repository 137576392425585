
const getInvalidMsg = (fieldName) => `${fieldName} can only contain up to 50 alphanumeric, comma, dash, underscore, hyphen, whitespace characters.`;

const form = {
  formId: "onboard-info",
  backend: {
    organizationName: {
      name: "organizationName",
      label: "Enter organization name",
      type: "text",
      placeholder: "Example Inc",
      errorMsg: "Organization name is required.",
      invalidMsg: getInvalidMsg("Organization name")
    },
    webDomain: {
      name: "webDomain",
      label: "Enter web domain name",
      type: "text",
      placeholder: "e.g example.com",
      errorMsg: "Domain name is required.",
      invalidMsg: "Domain name is invalid. Expects format like: example.com.",
      offlineMsg: "Domain is either not reachable, does not support HTTPS or is already associated with another Manzia account."
    },
    industryCode: {
      name: "industryCode",
      label: "Select your industry",
      type: "text",
      errorMsg: "Industry is required.",
      invalidMsg: "Select a valid industry from the dropdown."
    },
  },
  formField: {
    botName: {
      name: "botName",
      label: "Give your bot a name",
      type: "text",
      placeholder: "AI SalesBot",
      errorMsg: "Bot name is required.",
      invalidMsg: getInvalidMsg("Bot name")
    },
    botTone: {
      name: "botTone",
      label: "What tone should bot use?",
      type: "text",
      placeholder: "e.g informal",
      errorMsg: "Bot tone is required.",
      invalidMsg: "Bot tone can only contain up to 50 alphabetic characters and spaces."
    },
    productName: {
      name: "productName",
      label: "Enter your product name",
      type: "text",
      placeholder: "Example App",
      errorMsg: "Product name is required.",
      invalidMsg: getInvalidMsg("Product name")
    },
    productPage: {
      name: "productPage",
      label: "Product Page URL",
      type: "text",
      errorMsg: "Product page is required.",
      invalidMsg: "Product page URL is invalid.",
      placeholder: "www.example.com/product-page",
      offlineMsg: "URL is not reachable. It maybe offline or does not support HTTPS."
    },
    demos: {
      scheduleDemo: {
        name: "scheduleDemo",
        label: "Call to action URL",
        type: "text",
        errorMsg: "Call to action URL is required.",
        invalidMsg: "Invalid URL",
        placeholder: "e.g abccompany.com/schedule-demo"
      },
      demoType: {
        name: "demoType",
        label: "Call to action",
        type: "text",
        errorMsg: "Call to action is required.",
        invalidMsg: "Invalid call to action"
      },
      name: "demos",
      label: "Demos",
      type: "text",
    },
  },
};


export default form;
