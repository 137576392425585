import { useState, useEffect, useContext } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// GraphQL
import { gql, useMutation } from '@apollo/client';
// import * as mutations from 'graphql/mutations';
import CreateBotDeployment from 'layouts/deploy/publish/components/PublishButton/graphql';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

// Select
// import MenuItem from '@mui/material/MenuItem';


// Material Dashboard
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

// Auth
import { AuthUserContext } from "App";

// Manzia
import ProgressButton from 'layouts/common/ProgressButton';
import NotificationView from 'layouts/common/Notification';
import useLocalChatbotUser from 'customhooks/useLocalChatbotUser';

// UUID
import { v4 as uuidv4 } from 'uuid';

// Status
import { DeploymentStatus, ProductDeployState } from "appSettings";
import productValidation from "layouts/deploy/publish/components/validations";
import { isEmptyObject } from "helpers";

// Utilities
import moment from 'moment';

// Regex
// checks for alphanumeric with at least 2 chars
const FIELD_REGEX = /^[a-zA-Z0-9\s]{2,}$/;

// function getMenuItems(trainings) {
//   return trainings.map(item => {
//       const { id, userProduct: { id: productId, productName } } = item;
//       return (<MenuItem key={productId} value={id}>{productName}</MenuItem>);
//   });
// }

function isIncompleteProduct(training) {
  let trainingData;
  try {
    trainingData = JSON.parse(training.trainingData);
  } catch (error) { trainingData = null; }
  if (isEmptyObject(trainingData)) return false;
  return !productValidation.isValidSync(trainingData);
}

function getProductDeployState(trainings) {
  let complete = [];
  const incomplete = [];
  if (trainings.length === 0) return { state: ProductDeployState.NONE, complete, incomplete };
  trainings.forEach(training => {
    const pending = isIncompleteProduct(training);
    if (pending) { 
      incomplete.push(training); 
    } else {
      complete.push(training);
    }
  });
  complete = complete.filter(train => !train.botDeploymentId);
  if (complete.length > 0 && incomplete.length > 0) return { state: ProductDeployState.COMPLETE_INCOMPLETE, complete, incomplete };
  if (complete.length > 0) return {state: ProductDeployState.COMPLETE, complete, incomplete };
  if (incomplete.length > 0) return {state: ProductDeployState.INCOMPLETE, complete, incomplete };
  return { state: ProductDeployState.NONE, complete, incomplete };
}

function PublishButton({ chatbotId, children, trainings, onPublish }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { state, complete, incomplete } = getProductDeployState(trainings);
  const [open, setOpen] = useState(false);
  const [productTraining, setProductTraining] = useState(complete[0]);
  const [incompleteProduct, setIncompleteProduct] = useState(incomplete[0]);
  const [deployMessage, setDeployMessage] = useState("");
  const [deployMessageState, setDeployMessageState] = useState(false);
  const [createBotDeployment, { data, loading, error }] = useMutation(gql`${CreateBotDeployment}`);
  const navigate = useNavigate();
  const { chatbot, loginUser } = useLocalChatbotUser({ userId: USERID });

  useEffect(() => {
    if (!data) return;
    onPublish(data);
  }, [data]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setDeployMessage(e.target.value);
    if (FIELD_REGEX.test(e.target.value)) {
      setDeployMessageState(false);
    }
  }

  const handleProduct = (e, value) => {
    switch (e.target.name) {
      case 'select-product':
        setProductTraining(value);
        break;
      case 'incomplete-product':
        setIncompleteProduct(value);
        break;
      default:
        break;
    }
  }

  const createProduct = () => {
    setOpen(false);
    navigate("/new-product");
  }

  const editProduct = () => {
    setOpen(false);
    const selectedTraining = incomplete.find(train => train.id === incompleteProduct.id)
    // console.log("Selected training: ", selectedTraining);
    navigate(`/products/${selectedTraining?.userProductId}`);
  }

  // Views
  function noProductsView() {
    return (
      <>
         <DialogTitle>{`${chatbot.chatbotName} has no products.`}</DialogTitle>
         <DialogContent>
          <DialogContentText>
            {`You must add a product to ${chatbot.chatbotName} before you can publish a bot version.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton 
              variant="gradient"
              color="info" 
              onClick={createProduct}
          >
            Add Product
          </MDButton>
        </DialogActions>
      </>
    );
  }

  function productsView() {
    return (
      <>
        <DialogTitle>{`Publish new version of ${chatbot.chatbotName}.`}</DialogTitle>
        <DialogContent>
          {incomplete.length > 0 && 
          (
            <>
              <DialogContentText>
                Finish editing these products in order to add the changes to this bot version.
              </DialogContentText>
              {(error && complete.length === 0) && (
                <NotificationView 
                  color="error"
                  message="Encountered an error publishing this bot version. Please retry later."
                />
              )}
              {(data && complete.length === 0) && (
                <NotificationView 
                  color="success"
                  message={`A new version of ${chatbot.chatbotName} has been successfully published.`}
                />
              )}
              <MDBox my={2}>
              <Grid container spacing={3} justifyContent="center" alignItems="flex-end">
                <Grid item xs={12} sm={10}>
                <MDBox display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    Select product to edit
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  value={incompleteProduct}
                  name="incomplete-product"
                  autoComplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.userProduct?.productName ?? "Unknown"}
                  onChange={handleProduct}
                  options={incomplete.sort((a, b) => a.userProduct.productName.localeCompare(b.userProduct.productName))}
                  renderInput={(params) => <MDInput {...params} variant="standard" />}
                />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <MDButton 
                    variant="outlined" 
                    color="dark" 
                    fullWidth
                    onClick={editProduct}
                    disabled={incompleteProduct.length < 1}
                  >
                    Edit
                  </MDButton>
                </Grid>
              </Grid>
              </MDBox>
            </> 
          )}

          {complete.length > 0 && 
          (
            <>
              <DialogContentText>
                Enter a description for this new bot version.
              </DialogContentText>
              
              {error && <NotificationView 
                color="error"
                message="Encountered an error publishing this bot version. Please retry later."
              />}
              {data && <NotificationView 
                color="success"
                message={`A new version of ${chatbot.chatbotName} has been successfully published.`}
              />}
              <MDBox mt={2}>
                <MDInput
                  id="deploy-message"
                  label="Deployment Message"
                  type="text"
                  fullWidth
                  required
                  variant="standard"
                  value={deployMessage}
                  onChange={handleChange}
                  error={deployMessageState}
                  helperText={deployMessageState ? "Publish message is required" : ""}
                />
              </MDBox>
              <MDBox mt={3} mb={2}>
                <MDBox display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Select product to publish
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  value={productTraining}
                  name="select-product"
                  autoComplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.userProduct.productName}
                  onChange={handleProduct}
                  options={complete.sort((a, b) => a.userProduct.productName.localeCompare(b.userProduct.productName))}
                  renderInput={(params) => <MDInput {...params} variant="standard" />}
                />
              </MDBox>
            </>
          )}

          {complete.length === 0 && (
            <DialogContent>
              <DialogContentText>
                {`No product(s) available to publish. Make product changes then publish those changes to a new version of ${chatbot.chatbotName}.`}
              </DialogContentText>
            </DialogContent>
          )}
        </DialogContent>
      </>
    );
  }

  function getDialogContent() {
    switch (state) {
      case ProductDeployState.NONE:
        return noProductsView();
      case ProductDeployState.COMPLETE_INCOMPLETE:
      case ProductDeployState.COMPLETE:
      case ProductDeployState.INCOMPLETE:
        return productsView();
      default:
        return noProductsView();
    }
  }

  const handleDeploy = () => {
    if (!FIELD_REGEX.test(deployMessage)) {
      setDeployMessageState(true);
      return;
    }
    const now = moment();
    const trainArr = trainings.filter(train => train.id === productTraining.id);
    const { userProduct } = trainArr[0];
    createBotDeployment({
      variables: {
          botDeploymentId: uuidv4(),
          manziaChatbotId: chatbotId,
          accountId: chatbot.accountId,
          lastDeployedAt: now.toISOString(),
          userDeployMessage: deployMessage,
          productName: userProduct.productName,
          deployedUsername: loginUser.fullName,
          productTrainingId: productTraining.id,
          deployedByUserId: USERID,
          lastModifiedAt: now.toISOString(),
          status: DeploymentStatus.inprogress.key,
          versionToDeploy: chatbot?.deployedVersion ? chatbot.deployedVersion : "1.0" 
      }
    });
  };

  console.log("Complete: ", complete);
  console.log("Incomplete: ", incomplete);

  return (
    <div>
      <Tooltip 
        title="Make a version of product information live"
        placement="top">
          <MDButton 
            variant="outlined" 
            color={darkMode ? "white" : "dark"}
            fullWidth 
            onClick={handleClickOpen}
            // disabled={trainings.length === 0}
        >
          {children}
        </MDButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        {getDialogContent()}
        {complete.length > 0 && (
          <DialogActions>
            <MDButton 
                variant="outlined"
                color="dark" 
                onClick={handleClose}
            >
              Cancel
            </MDButton>
            {(data || error) ? 
              (<MDButton variant="gradient" color="dark" disabled>
                Publish
              </MDButton>) :
              (<ProgressButton 
                  variant="gradient"
                  color="info" 
                  clickHandler={handleDeploy}
                  loading={loading}
                  disabled={deployMessage.length === 0 || deployMessageState || error || loading}
                >
                  Publish
                </ProgressButton>)
            }
            
          </DialogActions>
        )}

        {(complete.length === 0 && state !== ProductDeployState.NONE) && (
          <DialogActions>
            <MDButton 
                variant="outlined"
                color="dark" 
                onClick={handleClose}
            >
              Cancel
            </MDButton>
            <MDButton 
                variant="gradient"
                color="info" 
                onClick={editProduct}
            >
              Edit Product
            </MDButton>
          </DialogActions>
        )}
        
      </Dialog>
    </div>
  );
}

// Typechecking props for BaseLayout
PublishButton.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  trainings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    userProduct: PropTypes.shape({
      id: PropTypes.string.isRequired,
      productName: PropTypes.string.isRequired
    })
  })).isRequired,
  onPublish: PropTypes.func.isRequired
};


export default  PublishButton;