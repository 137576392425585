/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard
// import SignInBasic from "layouts/authentication/sign-in/basic";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignUpCover from "layouts/authentication/sign-up/cover";
// import ResetCover from "layouts/authentication/reset-password/cover";

// Manzia Components
import ProductServices from "layouts/train/product-page";
import TrainProduct from "layouts/train/new-product";
// import TrainService from "layouts/train/new-service";
import TestProducts from "layouts/test/products";
import AppSettings from "layouts/settings";
import SettingItem from "layouts/settings/components/SettingItem";
import BasicInfo from "layouts/settings/components/BasicInfo";
import BotAnalytics from "layouts/analytics";
import ConversationRecords from "layouts/conversations";
// import WelcomeView from "layouts/onboard/components/WelcomeView";

// Material Dashboard 2 PRO React components
// import MDAvatar from "components/MDAvatar";

// @mui icons //
import Icon from "@mui/material/Icon";
import { OnboardRole } from "appSettings";

// Images
// import profilePicture from "assets/images/team-3.jpg";

const routes = [
  // { type: "title", title: "SETUP", key: "title-setup" },
  {
    type: "collapse",
    name: "Products",
    key: "products-services",
    icon: <Icon fontSize="medium">inventory</Icon>,
    collapse: [
      {
        name: "Product Page",
        key: "product-page",
        route: "/products",
        roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER ],
        component: <ProductServices />,
      },
      {
        name: "Add Product",
        key: "new-product",
        route: "/new-product",
        roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER ],
        component: <TrainProduct />,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  // { type: "title", title: "TEST", key: "test-setup" },
  {
    type: "collapse",
    name: "Test Bot",
    key: "test-products",
    route: "/test-product",
    icon: <Icon fontSize="medium">model_training</Icon>,
    roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER ],
    component: <TestProducts />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-2" },
  // { type: "title", title: "CONVERSATIONS", key: "bot-sessions" },
  {
    type: "collapse",
    name: "Conversations",
    key: "bot-conversations",
    route: "/session",
    icon: <Icon fontSize="medium">people</Icon>,
    roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER ],
    component: <ConversationRecords />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Welcome",
  //   key: "onboardWelcome",
  //   route: "/onboard/welcome",
  //   icon: <Icon fontSize="medium">people</Icon>,
  //   component: <WelcomeView />,
  //   noCollapse: true,
  //   roles: [ OnboardRole.ACCOUNT_OWNER ]
  // },
  { type: "divider", key: "divider-3" },
  // { type: "title", title: "ANALYTICS", key: "bot-metrics" },
  {
    type: "collapse",
    name: "Analytics",
    key: "bot-analytics",
    route: "/analytics",
    icon: <Icon fontSize="medium">analytics</Icon>,
    roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER, OnboardRole.DEVELOPER ],
    component: <BotAnalytics />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-4" },
  // { type: "title", title: "SETTINGS", key: "app-settings" },
  { 
    type: "collapse",
    name: "Settings", 
    key: "settings", 
    route: "/settings",
    icon: <Icon fontSize="medium">settings</Icon>,
    noCollapse: true,
    nested: true,
    nestedKey: "settingItem",
    nestedComponent: <SettingItem />,
    indexComponent: <BasicInfo />,  
    component: <AppSettings />,
    roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER, OnboardRole.DEVELOPER ],
  },
  // {
  //   type: "collapse",
  //   name: "Authentication",
  //   key: "authentication",
  //   icon: <Icon fontSize="medium">login</Icon>,
  //   collapse: [
  //     {
  //       name: "Sign In",
  //       key: "sign-in",
  //       collapse: [
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/",
  //           component: <SignInIllustration />,
  //           roles: [ OnboardRole.PUBLIC ],
  //         },
  //       ],
  //     }
  //   ],
  // },
  { type: "divider", key: "divider-5" },
  {
    type: "collapse",
    name: "Help",
    key: "basic",
    icon: <Icon fontSize="medium">help</Icon>,
    collapse: [
      {
        name: "Getting Started",
        key: "getting-started",
        collapse: [
          {
            name: "Sign up",
            key: "sign-up",
            href: "https://www.manzia.com/help/getting-started/sign-up/",
          },
          {
            name: "Products",
            key: "bot-products",
            href: "https://www.manzia.com/help/getting-started/bot-products/",
          },
          {
            name: "Customize",
            key: "bot-customize",
            href: "https://www.manzia.com/help/getting-started/bot-customize/",
          },
          {
            name: "Styling",
            key: "bot-styling",
            href: "https://www.manzia.com/help/getting-started/bot-styling/",
          },
          {
            name: "Deploy",
            key: "bot-deploy",
            href: "https://www.manzia.com/help/getting-started/bot-deploy/",
          },
          {
            name: "Publish",
            key: "bot-publish",
            href: "https://www.manzia.com/help/getting-started/bot-publish/",
          },
          {
            name: "Add Funds",
            key: "add-funds",
            href: "https://www.manzia.com/help/getting-started/add-funds/",
          },
        ],
      },
      {
        name: "Performance",
        key: "performance",
        collapse: [
          {
            name: "Engagements",
            key: "bot-engagements",
            href: "https://www.manzia.com/help/performance/bot-engagements/",
          },
          {
            name: "Conversations",
            key: "bot-conversations",
            href: "https://www.manzia.com/help/performance/bot-conversations/",
          },
          {
            name: "Leads",
            key: "bot-leads",
            href: "https://www.manzia.com/help/performance/bot-leads/",
          },
          {
            name: "Triggers",
            key: "bot-triggers",
            href: "https://www.manzia.com/help/performance/bot-triggers/",
          },
        ],
      },
      {
        name: "Security",
        key: "security",
        collapse: [
          {
            name: "MFA",
            key: "account-mfa",
            href: "https://www.manzia.com/help/security/account-mfa/",
          },
          {
            name: "Team",
            key: "account-team",
            href: "https://www.manzia.com/help/security/account-team/",
          },
        ],
      },
    ],
  },
];

export default routes;
